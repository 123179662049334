import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.habilitarEdicionNombre()}}},[_c(VListItemTitle,[_vm._v("Modificar nombre")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.habilitarEdicionIcono()}}},[_c(VListItemTitle,[_vm._v("Modificar ícono")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-image-edit")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.validarTipoClonado()}}},[_c(VListItemTitle,[_vm._v("Clonar curso")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1),(!_vm.curso.escuela)?_c(VListItem,{on:{"click":function($event){return _vm.habilitarArchivarCurso()}}},[_c(VListItemTitle,[_vm._v("Archivar curso")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-file-cabinet")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }