<template>
  <v-menu offset-x left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="habilitarEdicionNombre()">
        <v-list-item-title>Modificar nombre</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-square-edit-outline</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item @click="habilitarEdicionIcono()">
        <v-list-item-title>Modificar ícono</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-image-edit</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item @click="validarTipoClonado()">
        <v-list-item-title>Clonar curso</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-content-copy</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item v-if="!curso.escuela" @click="habilitarArchivarCurso()">
        <v-list-item-title>Archivar curso</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-file-cabinet</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: "cursoCampusMenu",
  props: {
    curso: Object,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    habilitarEdicionIcono(){
      this.$emit("habilitarEdicionIcono");
    },
    habilitarEdicionNombre(){
      this.$emit("habilitarEdicionNombre");
    },
    habilitarClonadoCurso(){
      this.$emit("habilitarClonadoCurso");
    },
    validarTipoClonado(){
      if (this.curso.vigencia && this.curso.empresa) this.$emit("habilitarClonadoCursoImportado");
      else this.$emit("habilitarClonadoCurso");
    },
    habilitarArchivarCurso(){
      this.$emit("habilitarArchivarCurso");
    },
  }
};
</script>
