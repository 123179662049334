<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong>{{titulo}}</strong></v-toolbar
      >
      <v-container>
        <v-text-field
          dense
          outlined
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="nombreErrors"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
        ></v-text-field>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          :loading="loading"
          :class="{ 'disable-btn': nombreErrors.length > 0 || !nombre }"
          color="primary"
          small
          @click="realizarAccion()"
          >{{accion}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { crearCursoCampusService } from "./listadoCursosCampus.service";
import { modificarNombreCursoService } from "../../curso/curso.service";

export default {
  name: "agregarCursoCampus",
  props: {
    mostrar: Boolean,
    limiteCursos: Number,
    nombreAnterior: String,
    curso: { type: String, default: null },
    tipo: { type: String, default: null},
  },
  mixins: [validationMixin],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      !this.$v.nombre.minLength && errors.push("Mínimo 5 caracteres.");
      return errors;
    },
  },
  validations: {
    nombre: { required, minLength: minLength(5) },
  },
  data: () => ({
    loading: false,
    nombre: null,
    titulo: 'Nuevo curso en blanco',
    accion: 'Agregar',
  }),
  created(){
    if (this.nombreAnterior) {
      this.nombre = this.nombreAnterior;
      this.titulo = 'Modificar nombre';
      this.accion = 'Modificar';
    }
  },
  methods: {
    realizarAccion(){
      if (this.nombreAnterior) this.modificarNombre();
      else this.crearCursoCampus();
    },
    async crearCursoCampus() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const data = { nombreCurso: this.nombre };
          const serverResponse = await crearCursoCampusService(data);
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            const emitData = { curso: serverResponse.nuevoCurso };
            this.$emit("cursoAgregado", emitData);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
    async modificarNombre(){
      try {
        if(this.tipo) this.tipoCurso = this.tipo
        else if (this.$route.params.tipoCurso != "campus") this.tipoCurso = "plantilla";

        if(this.curso) this.idCurso = this.curso;
        else this.idCurso = this.$route.params.idCurso;

        this.loading = true;
        const data = { tipoCurso: this.tipoCurso, idCurso: this.idCurso, nombre: this.nombre };
        const serverResponse = await modificarNombreCursoService(data);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cursoModificado", serverResponse.nombre);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
