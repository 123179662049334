<template>
  <v-list-item>
    <v-list-item-avatar v-if="!curso.icon">
      <v-avatar size="50" color="secondary">
        <v-icon color="white">mdi-book-open-outline</v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-avatar v-else size="40">
      <v-img :src="`${imageURL}/${curso.icon}`" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <a
          v-if="!curso.bloqueadoProfesor && !curso.bloqueadoEscuela"
          href="javascript:void(0)"
          class="link"
          @click="abrirCurso(curso._id)"
          >{{ curso.nombre }}</a
        >
        <template v-else>
          {{ curso.nombre }}
        </template>
      </v-list-item-title>
      <v-list-item-subtitle>
        <genericoChipComponent :text="curso.modulos" icon="mdi-view-list" />
        <genericoChipComponent
          :text="curso.totalActividades"
          icon="mdi-format-list-text"
        />
        <genericoChipComponent
          v-if="!curso.sesionAlumno"
          :text="curso.alumnos"
          icon="mdi-account"
        />
        <!-- <genericoChipComponent
          :text="curso.actividadesPendientes"
          :icon="
            curso.actividadesPendientes == 0 ? 'mdi-tray' : 'mdi-tray-full'
          "
        /> -->
        <genericoChipComponent
          :text="`${curso.avance}%`"
          icon="mdi-finance"
        />
        <v-chip
          v-if="curso.bloqueadoEscuela || curso.bloqueadoProfesor"
          color="red darken-2"
          x-small
          outlined
        >
          <span v-if="curso.bloqueadoEscuela">Bloqueado por la escuela </span>
          <span v-if="curso.bloqueadoProfesor">Bloqueado por el profesor </span>
        </v-chip>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip
          v-if="curso.vigencia"
          :color="colorVigencia"
          label
          small
          outlined
        >
          <span>{{ textoVigencia }}</span>
        </v-chip>
        <span v-if="curso.vigencia"> {{ curso.empresa.nombreCorto }}</span>
        <span v-if="curso.vigencia && curso.escuela"> - </span>
        <span v-if="curso.escuela && curso.escuela.nombreCorto">
          {{ curso.escuela.nombreCorto }} - {{ curso.sede }}</span
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <cursoCampusMenuComponent
        v-if="curso.permisoEscritura"
        :curso="curso"
        @habilitarEdicionIcono="habilitarEdicionIcono"
        @habilitarEdicionNombre="habilitarEdicionNombre"
        @habilitarClonadoCurso="habilitarClonadoCurso"
        @habilitarClonadoCursoImportado="habilitarClonadoCursoImportado"
        @habilitarArchivarCurso="habilitarArchivarCurso"
      />
      <cursoCampusMenuArchivadosComponent
        v-if="!curso.permisoEscritura && !curso.escuela && curso.archivado"
        :curso="curso"
        @habilitarArchivarCurso="habilitarArchivarCurso"
      />
    </v-list-item-action>

    <modificarIconoComponent
      v-if="modificarIcono"
      :mostrar="modificarIcono"
      :idCurso="curso._id"
      :tipoCurso="'campus'"
      @cancelar="modificarIcono = false"
      @iconoModificado="iconoModificado"
    />

    <agregarCursoCampusComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombreAnterior="curso.nombre"
      :curso="curso._id"
      :tipo="'campus'"
      @cancelar="modificarNombre = false"
      @cursoModificado="cursoModificado"
    />

    <clonarCursoImprtadoComponent
      v-if="clonarImportada"
      :mostrar="clonarImportada"
      :idCurso="curso._id"
      :accion="accion"
      @plantillaImportadaClonada="plantillaImportadaClonada"
      @cursoReActivado="cursoReActivado"
      @cursoActivado="cursoActivado"
      @cancelar="clonarImportada = false"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import genericoChipComponent from "../../../widgets/chips/genericoChip.vue";
import agregarCursoCampusComponent from "./agregarCursoCampus.vue";
import cursoCampusMenuComponent from "./cursoCampusMenu.vue";
import modificarIconoComponent from "../modificarIconoCurso.vue";
import clonarCursoImprtadoComponent from "./clonarCursoImportado.vue";
import { crearSesionTokenControlParental } from "../../../controlParental/controlParental.service";
import { DateTime } from "luxon";
import cursoCampusMenuArchivadosComponent from "./cursoCampusMenuArchivados.vue";

export default {
  name: "CursoCampusItem",
  props: {
    curso: Object,
    hijo: String,
  },
  components: {
    genericoChipComponent,
    cursoCampusMenuComponent,
    agregarCursoCampusComponent,
    modificarIconoComponent,
    clonarCursoImprtadoComponent,
    cursoCampusMenuArchivadosComponent,
  },
  computed: {
    ...mapGetters(["role", "app", "imageURL", "userId", "sesionCursoPadre"]),
  },
  watch: {
    "curso.vigencia"() {
      this.validarFechaCursoImportado();
    },
  },
  created() {
    this.validarFechaCursoImportado();
  },
  data: () => ({
    loading: false,
    loadingAvance: false,
    modificarNombre: false,
    modificarIcono: false,
    clonarImportada: false,
    textoVigencia: null,
    colorVigencia: "red",
    accion: "",
  }),
  methods: {
    abrirCurso(idCurso) {
      if (
        this.curso.vigencia &&
        !this.curso.esProfesor &&
        (!this.curso.activacion ||
          DateTime.fromISO(this.curso.activacion.vigencia) < DateTime.now()) &&
        !this.sesionCursoPadre
      ) {
        this.accion = "activarCurso";
        this.clonarImportada = true;
      } else if (
        this.curso.vigencia &&
        DateTime.fromISO(this.curso.vigencia) < DateTime.now()
      ) {
        if (
          (this.curso.activacion &&
            DateTime.fromISO(this.curso.vigencia) <
              DateTime.fromISO(this.curso.activacion.vigencia)) ||
          (this.curso.profesor == this.userId && this.curso.escuela) ||
          this.sesionCursoPadre
        )
          this.$systemErrorMessage(
            "Curso expirado, pongase en contacto con el propietario del curso"
          );
        else {
          this.accion = "renovarCodigo";
          this.clonarImportada = true;
        }
      } else if (this.sesionCursoPadre == true)
        this.obtenerTokenControlParental(idCurso);
      else this.$router.push(`/curso/campus/${idCurso}#encuadre`);
    },
    habilitarEdicionIcono() {
      this.modificarIcono = true;
    },
    habilitarEdicionNombre() {
      this.modificarNombre = true;
    },
    habilitarClonadoCurso() {
      this.$emit("clonarCurso", this.curso._id);
    },
    habilitarClonadoCursoImportado() {
      this.accion = "clonarImportada";
      this.clonarImportada = true;
    },
    plantillaImportadaClonada(curso) {
      this.$emit("cursoImportadoClonado", curso);
    },
    cursoReActivado(curso) {
      this.curso.vigencia = curso.vigencia;
    },
    cursoActivado(codigoActivado) {
      this.curso.activacion = codigoActivado;
      this.$router.push(`/curso/campus/${this.curso._id}#encuadre`);
    },
    iconoModificado(icono) {
      this.curso.icon = icono;
      this.modificarIcono = false;
    },
    cursoModificado(nombreCurso) {
      this.modificarNombre = false;
      this.curso.nombre = nombreCurso;
    },
    validarFechaCursoImportado() {
      this.colorVigencia = "red";
      if (this.curso.vigencia) {
        if (DateTime.fromISO(this.curso.vigencia) < DateTime.now()) {
          this.textoVigencia = "Curso vencido";
          return;
        }
        //Pertenece a una escuela, no es profesor y no tiene código
        if (
          this.curso.escuela &&
          !this.curso.esProfesor &&
          !this.curso.activacion
        ) {
          this.textoVigencia = "Requiere código";
          return;
        }
        //Curso sin escuela
        if (!this.curso.escuela && !this.curso.activacion) {
          this.textoVigencia = "Requiere código";
          return;
        } else {
          this.colorVigencia = "green";
          this.textoVigencia = DateTime.fromISO(this.curso.vigencia).toFormat(
            "dd-MM-yyyy"
          );
        }
      }
    },
    async obtenerTokenControlParental(idCurso) {
      try {
        this.loading = true;
        const data = { idAlumno: this.hijo };
        const serverResponse = await crearSesionTokenControlParental(data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$store.dispatch(
            "setTokenSesionHijo",
            serverResponse.tokenSesionHijo
          );
          localStorage.setItem(
            "agnus-padre-token",
            serverResponse.tokenSesionHijo
          );
          this.$router.push({ path: `/curso/campus/${idCurso}#encuadre` });
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async habilitarArchivarCurso() {
      const dataCurso = {
        idCurso: this.curso._id,
        archivado: this.curso.archivado,
      };
      this.$emit("archivarCurso", dataCurso);
    },
  },
};
</script>
