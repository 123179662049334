<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat dense class="text-h6">
        <v-spacer></v-spacer>
        Control parental
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row v-if="hijos.length > 0" class="mx-1 margenButton">
        <v-col cols="12">
          <v-row>
            <v-col md="6" cols="12">
              <v-spacer></v-spacer>
              <v-select
                :items="hijos"
                v-model="hijoSel"
                item-text="hijo.nombreCompleto"
                item-value="hijo._id"
                no-data-text="No hay hijos vinculados"
                label="Hijos"
                :disabled="loading"
                @change="cargarCursos(hijoSel)"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-card outlined>
            <v-list v-if="cursos.length > 0">
              <template v-for="(curso, index) in cursos">
                <cursoCampusItemComponent
                  :key="`cursoItem-${curso._id}`"
                  :curso="curso"
                  :hijo="hijoSel"
                />
                <v-divider
                  v-if="index < cursos.length - 1"
                  :key="`cursoItemDivider-${curso._id}`"
                  class="mx-3"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-card-text v-else style="text-align: center">
        <p>No hay hijos registrados.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import { getInvitacionesService, getCursosHijoControlParental } from './controlParental.service';
import cursoCampusItemComponent from "../cursos/listadoCursos/cursosCampus/cursoCampusItem.vue";

export default {
  name: "ControlView",
  components: {
     cursoCampusItemComponent
  },
  computed: {
    ...mapGetters(["role", "sessionToken", "userId"]),
  },
  watch: {
    sessionToken: function (val) {
      this.cargarInvitaciones();
    },
  },
  data() {
    return {
      loading: false,
      hijos: [],
      hijoSel: null,
      cursos: [],
    };
  },
  created() {
    if (this.sessionToken) this.cargarInvitaciones();
  },
  mounted() {
  },
  methods: {
    async cargarInvitaciones() {
      try {
        this.loading = true;
        const serverResponse = await getInvitacionesService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.hijos = serverResponse.invitaciones;
          if(this.hijos.length > 0){
            this.hijoSel = this.hijos[0].hijo._id;
            this.cargarCursos(this.hijoSel);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async cargarCursos(idHijo) {
      try {
        this.loading = true;
        const data = {idHijo}
        const serverResponse = await getCursosHijoControlParental(data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.cursos = serverResponse.cursosUsuario;
          this.$store.dispatch("setSesionCursoPadre", serverResponse.sesionPadre);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style>
  .margenButton {
    margin: 0px 5px 0px 5px;
  }
</style>