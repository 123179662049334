<template>
  <v-menu offset-x left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-if="!curso.escuela" @click="habilitarArchivarCurso()">
        <v-list-item-title>Sacar curso del archivo</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-file-cabinet</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: "cursoCampusMenuArchivados",
  props: {
    curso: Object,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    habilitarArchivarCurso(){
      this.$emit("habilitarArchivarCurso");
    },
  }
};
</script>
