<template>
  <v-dialog v-model="mostrar" persistent max-width="250">
    <v-card class="mx-auto" :loading="loading">
      <v-container class="pa-2" fluid>
        <croppa
          v-model="myCroppa"
          accept=".jpeg, .png, .jpg"
          :width="230"
          :height="230"
          placeholder="Click para seleccionar una imagen"
          :placeholder-font-size="12"
          :prevent-white-space="true"
          @file-choose="rotacion = true"
          :showRemoveButton="false"
        />
        <!-- @click="myCroppa.chooseFile()" -->
        <v-card-actions v-if="rotacion" class="justify-center">
          <v-btn @click="myCroppa.remove(), cambiar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn @click="myCroppa.rotate(-1)">
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn @click="myCroppa.rotate()">
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>

        <v-card-actions v-if="rotacion" class="justify-center">
          <v-btn @click="myCroppa.flipX()">
            <v-icon>mdi-gesture-swipe-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn @click="myCroppa.flipY()">
            <v-icon>mdi-gesture-swipe-up</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!loading"
            color="primary"
            outlined
            small
            @click="$emit('cancelar', false); rotacion = false;"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="rotacion"
            color="primary"
            dark
            small
            :loading="loading"
            @click="guardarIcon(); rotacion = false;"
          >
            Guardar
          </v-btn>
        </v-card-actions>

        <v-col>
          <v-progress-linear v-if="!terminado" :value="progreso" :max="100" show-progress animated></v-progress-linear>
        </v-col>
      </v-container>
    </v-card>
    <v-dialog v-model="vacio" max-width="500">
      <v-card>
        <v-container grid-list-md>
          <v-row wrap>
            <v-col cols="12">
              <v-alert color="primary" dark outlined dense prominent>
                <p>No se selecciono ninguna imagen.</p>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="vacio = false"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="noValido" max-width="500">
      <v-card>
        <v-container grid-list-md>
          <v-row wrap>
            <v-col cols="12">
              <v-alert color="primary" dark outlined dense prominent>
                <p>El archivo que seleccionaste no es una imagen.</p>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="noValido = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>

import { mapGetters } from "vuex";
import { modificarPlantillaIconService } from "./cursoPlantilla/cursoPlantilla.service"
import { modificarCampusIconService } from "./cursosCampus/listadoCursosCampus.service"
export default {
  props: ["mostrar", "idCurso", "tipoCurso"],
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
  },
  data() {
    return {
      loading: false,
      myCroppa: {},
      vacio: false,
      noValido: false,
      rotacion: false,
      terminado: true,
      progreso: 0,
    };
  },
  methods: {
    async guardarIcon() {
      try {
        if (this.myCroppa.chosenFile === null) {
          this.vacio = true;
          this.noValido = false;
        } else {
          if (this.myCroppa.imageSet === false) {
            this.noValido = true;
            this.vacio = false;
          } else {
            this.terminado = false;
            this.img = this.myCroppa.generateDataUrl("image/jpg");
            var arr = this.img.split(","),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const image = new FormData();
            image.append(
              "image",
              new File([u8arr], "imagen.jpg", { type: mime })
            );
            this.loading = true;
            const uploadHandler = {
              onUploadProgress: (progressEvent) => {
                this.progreso = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (this.progreso >= 98) {
                    this.terminado = true;
                    this.procesando = true;
                }
              },
            };

            const data = {image, tipoCurso: this.tipoCurso, uploadHandler, idCurso: this.idCurso};
            let serverResponse = null;
            if (this.tipoCurso == 'plantilla') serverResponse = await modificarPlantillaIconService(data);
            else serverResponse = await modificarCampusIconService(data);
            this.loading = false;
            if (!serverResponse.ok) {
                this.$systemErrorMessage(serverResponse.mensaje);
            } else {
                this.myCroppa.remove();
                this.$emit("iconoModificado", serverResponse.curso.icon);
            }
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
      this.myCroppa.chosenFile = null;
    },
    cancelar() {
      this.myCroppa.remove();
      this.$emit("cancelar");
    },
    cambiar() {
      this.rotacion = false;
    },
  },
};
</script>