<template>
  <v-dialog v-model="mostrar" width="370" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong>{{label}}</strong></v-toolbar
      >
      <v-container>
        <v-text-field
        class="separador"
          dense
          outlined
          v-model="codigo"
          label="Código"
          :disabled="loading"
          :error-messages="codigoErrors"
          @input="$v.codigo.$touch()"
          @blur="$v.codigo.$touch()"
        ></v-text-field>
       
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          :class="{ 'disable-btn': codigoErrors.length > 0 || !codigo }"
          color="primary"
          small
          @click="clonarCursoImportado()"
        >
          {{labelButton}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { clonarCursoImportadoService, renovarCodigoCursoService, activarCodigoCursoService } from './listadoCursosCampus.service';

export default {
  components: {
  },
  props: {
    mostrar: Boolean,
    idCurso: String,
    accion: String
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "tipoSesion"]),
    codigoErrors() {
      const errors = [];
      if (!this.$v.codigo.$dirty) return errors;
      !this.$v.codigo.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    codigo: { required },
  },
  data: () => ({
    loading: false,
    codigo: null,
    label: 'Clonar curso importado',
    labelButton: 'Importar'
  }),
  created(){
    if (this.accion == 'renovarCodigo') {
      this.label = 'Renovar curso';
      this.labelButton = 'Aplicar'
    }
    if (this.accion == 'activarCurso') {
      this.label = 'Activar curso';
      this.labelButton = 'Activar'
    }
  },
  methods: {
    async clonarCursoImportado() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          let data = { 
            codigo: this.codigo,
            idCurso: this.idCurso,
            tiposSesion: this.tipoSesion
          }

          let serverResponse = null
          if (this.accion == 'clonarImportada') {
            serverResponse = await clonarCursoImportadoService(data);

            if (!serverResponse.ok) {
              this.$systemErrorMessage(serverResponse.mensaje);
              this.loading = false;
            } else this.$emit("plantillaImportadaClonada", serverResponse.cursoClonado);

          } else if (this.accion == 'renovarCodigo') {
            serverResponse = await renovarCodigoCursoService(data);

            if (!serverResponse.ok) {
              this.$systemErrorMessage(serverResponse.mensaje);
              this.loading = false;
            } else this.$emit("cursoReActivado", serverResponse.cursoReActivado);
          } else {
            serverResponse = await activarCodigoCursoService(data);

            if (!serverResponse.ok) {
              this.$systemErrorMessage(serverResponse.mensaje);
              this.loading = false;
            } else this.$emit("cursoActivado", serverResponse.codigoActivado);
          }

          this.loading = false;
          this.$emit('cancelar');

        } catch (error) {
          this.loading = false;
          this.$emit('cancelar');
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
<style scoped>
.separador{
  margin-bottom: 30px;
}
</style>