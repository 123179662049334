import { postServerRequest, putServerRequest, patchServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerCursosUsuarioService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursoCampus/cursos`;
    return await postServerRequest(url, data ,config);
}

export async function crearCursoCampusService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursoCampus/curso`;
    return await putServerRequest(url, data, config);
}

export async function registrarClaveService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/registro/clave`;
    return await putServerRequest(url, data, config);
}

export async function modificarCampusIconService(data) {
    const token = store.getters.sessionToken;
    const config = { 
        headers: { token },
        ...data.uploadHandler
    };
    const url = `${httpURL}/curso/${data.tipoCurso}/icono/${data.idCurso}`;
    return await patchServerRequest(url, data.image, config);
}

export async function clonarCursoService(idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/clonar/${idCurso}`;
    return await patchServerRequest(url, {}, config);
}

export async function importarCursoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/importar/from/empresa`;
    return await patchServerRequest(url, data, config);
}

export async function clonarCursoImportadoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/clonar/importada/from/empresa`;
    return await patchServerRequest(url, data, config);
}

export async function renovarCodigoCursoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/renovar/codigo/curso`;
    return await patchServerRequest(url, data, config);
}

export async function activarCodigoCursoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/activar/codigo/curso`;
    return await patchServerRequest(url, data, config);
}

export async function modificarArchivadoCursoService(idCurso, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursoCampus/archivar/curso/${idCurso}`;
    return await postServerRequest(url, data ,config);
}